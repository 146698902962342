import { format, isBefore, parseISO } from "date-fns";

const contentful = require("contentful");

const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

export const getContentfulNews = async ({ langId, portal }) => {
    const { items } = await client.getEntries({
        content_type: "recentNews",
        "fields.language[in]": langId,
        "fields.application[in]": portal
    });
    const now = format(new Date(), "yyyy-MM-dd'T'HH:mmxxx");
    const availableItems = items.filter((item) =>
        isBefore(parseISO(now), parseISO(item.fields.dueDate))
    );

    const sortedItems = availableItems.sort(
        (a, b) => new Date(b.sys.createdAt) - new Date(a.sys.createdAt)
    );

    return sortedItems;
};

export const getTranslations = async ({ langId, portal }) => {
    const { items, total } = await client.getEntries({
        content_type: "translation",
        select: "fields.data",
        "fields.product[in]": portal,
        "fields.language[in]": langId
    });

    if (total > 1) {
        console.warning("There is more than one language data, please check data!");
    }
    if (total < 1) {
        console.error("There is no language data. Add language file or check language id!");
        return {};
    }
    return items[0].fields.data;
};
